import React, { useEffect, useState } from 'react';
import logo_bn from './images/logo-lookup-data-magnet-blanco.svg' 
import { useNavigate } from 'react-router-dom/dist';
import { useAuth } from './Context/authContext';
import axios from 'axios';

import './Dashboard.css'
import './Header.css'

const Dashboard = () => {
  const [products, setProducts] = useState([
  ]);

  const [filters, setFilters] = useState({
    category: '',
    price: '',
    relevance: '',
    potential: '',
  });
  const [filteredProducts, setFilteredProducts] = useState([]);

  const { logout, user } = useAuth();
  const navigate = useNavigate();

  // Function to update filters
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
    // Implement filtering logic here based on selected filters
  };

  // Función para llamar a la función lambda de AWS
  const callNewProduct = async (payload) => {
    try {
      const response = await axios.post(
        'https://cdy7us0fy4.execute-api.us-east-1.amazonaws.com/default/get_products_py',
        payload
      );
  
      if (response.status === 200) {
        console.log(response.data)
        const modifiedProducts = response.data.map(product => ({
          ...product,
          thumbnail_url: product.thumbnail_url.replace(/-I\.jpg$/, '-O.jpg')
        }));

        setProducts(modifiedProducts)
        console.log(modifiedProducts); // Log the response data if request is successful
      } else {
        console.error('Error al llamar al servidor:', response.data);
      }
    } catch (error) {
      console.error('Error al procesar la solicitud:', error);
    }
  };

  // Función para aplicar los filtros
  const applyFilters = () => {
    // Aquí podrías construir el payload con los filtros actuales (filters)
    const payload = {
      max_quantity: 1
    };

    // Llamada a la función lambda con el payload
    callNewProduct(payload);
    console.log('Filters applied:', filters);
    // Puedes realizar más operaciones o actualizaciones aquí si es necesario
  };

  const categorizePotential = (potentialValue) => {
    if (potentialValue < 0.04) {
      return 'Bajo';
    } else if (potentialValue >= 0.04 && potentialValue < 0.25) {
      return 'Medio';
    } else if (potentialValue >= 0.25 && potentialValue < 1) {
      return 'Alto';
    } else if (potentialValue >= 1 && potentialValue < 5) {
      return 'Muy alto';
    } else {
      return '⭐ Producto estrella ⭐';
    }
  };

  const handleLogout = () => {
    const confirmLogout = window.confirm('¿Seguro que deseas cerrar sesión?');
  
    if (confirmLogout) {
      logout();
      navigate('/');
    }
  };


  useEffect(() => {
    // Call the Lambda function with max_quantity: 50 when component mounts
    callNewProduct({ max_quantity: 1 });
  }, []); 
  return (
    <div className="marketplace-container">
      <header class="Header">
          <h1><a className='brand' href="https://aiml.framer.ai">AIML <i style={{fontSize:"20px"}}>Beta 1.0</i></a></h1>
          <nav>
            <ul>
              <li><a onClick={handleLogout}>Cerrar Sesión</a></li>
            </ul>
          </nav>
        </header>

      {/*
      <h2>Aplicar filtros</h2>
      */}
        {/*
      <div className="filter-bar">

        <select
          name="category"
          value={filters.category}
          onChange={handleFilterChange}
        >
          <option value="">Categorías</option>
          <option value="Category A">Categoría A</option>
          <option value="Category B">Categoría B</option>
        </select>

        <select
          name="potential"
          value={filters.potential}
          onChange={handleFilterChange}
        >
          <option value="">Potencial</option>
          <option value="4">Muy alto</option>
          <option value="3">Alto</option>
          <option value="2">Medio</option>
          <option value="1">Bajo</option>
        </select>
        
        <select
          name="price"
          value={filters.price}
          onChange={handleFilterChange}
        >
          <option value="">Precios</option>
          <option value="300">0-300</option>
          <option value="500">300-500</option>
          <option value="1000">500-1000</option>
          <option value="5000">1000+</option>
        </select>

        <select
          name="relevance"
          value={filters.relevance}
          onChange={handleFilterChange}
        >
          <option value="">Antiguedad</option>
          <option value="recent">Más recientes</option>
          <option value="popular">Más antiguos</option>
        </select>

        <button onClick={applyFilters}>Aplicar</button>
      </div>
      */}

      {/* Product cards */}
      <div className="product-list">
        {products.map((product) => (
          <div className="product-card" key={product?.product_id}>
            <a href={product?.permalink} target='_blank'>
              <img src={product?.thumbnail_url} alt={product?.title} />
              <h3><i>{product?.title}</i></h3>
              <p>Categoría: {product?.category_id}</p>
              <p>Precio: ${product?.price}</p>
              <p>Potencial: {categorizePotential(product?.potential)}</p>
              <p>Creado el: {new Date(product?.date_created).toLocaleString('en-US')}</p>
            </a>
          </div>
        ))}
      </div>
      <div className='product-display'>
        <button onClick={()=>callNewProduct({
        max_quantity: 1
        })}>Ver otro producto</button>
      </div>
    </div>
  );
};

export default Dashboard;
